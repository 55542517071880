body[dir="rtl"] .reverse.displayFlexEnd,
body[dir="rtl"] .reverse.loginPageEmailLink,
body[dir="rtl"] #headerInfoContainer.reverse,
body[dir="rtl"] #readMoreAndIcon.reverse,
body[dir="rtl"] #conclusionPageHeaderHeading.reverse,
body[dir="rtl"] #filterContainer.reverse,
body[dir="rtl"] .editSubjectButtonContainer.reverse,
body[dir="rtl"] .reverse .arena-dropdown__value-container,
body[dir="rtl"] .reverse .arena-select__value-container,
body[dir="rtl"] #resetPasswordPageEmailLink.reverse {
  justify-content: flex-start !important;
}

body[dir="rtl"] .reverse.displayFlex,
body[dir="rtl"] .reverse.sendButton .MuiButton-label,
body[dir="rtl"] #backToCategoriesButton.reverse .MuiButton-label,
body[dir="rtl"] .categoryActiveDiscussionContainer.reverse,
body[dir="rtl"] .discussCardShareContainer.reverse,
body[dir="rtl"] #createCategoriInputFieldContainer.reverse,
body[dir="rtl"] #createCategoriUploaderContainer.reverse,
body[dir="rtl"] .categoryDiscussHeaderInfo.reverse,
body[dir="rtl"] .discussSubjectCommentsContainer.reverse,
body[dir="rtl"] #commentFilterMainContainer.reverse,
body[dir="rtl"] .commentComActionButtons.reverse,
body[dir="rtl"] .displayFlex.reverse,
body[dir="rtl"] .discussSubjecHeaderInfo.reverse,
body[dir="rtl"] #conclusionPageContainer.reverse,
body[dir="rtl"] #allocationPageChartMainContainer.reverse,
body[dir="rtl"] .allocationLegendContainer.reverse,
body[dir="rtl"] .editSubjectTextAndDropDownContainer.reverse,
body[dir="rtl"] .dropdownContainer.reverse .MuiFormGroup-root,
body[dir="rtl"] .dropDownIconsContainer.reverse,
body[dir="rtl"] #imageUploaderContainer.reverse,
body[dir="rtl"] #userControlPanelContainer.reverse,
body[dir="rtl"] .myCommentCountContainer.reverse,
body[dir="rtl"] .reverseMessageActionButton,
body[dir="rtl"] #updateModalActionContainer.reverse,
body[dir="rtl"] .changePasswordButton.reverse .MuiButton-label,
body[dir="rtl"] #shareModalButtonContainer.reverse,
body[dir="rtl"] .dateComponentContainer,
body[dir="rtl"] .activeDiscussionDeadlineContainer,
body[dir="rtl"] #readMoreAndIcon {
  flex-direction: row-reverse !important;
}

body[dir="rtl"] .categoryListContainer.reverse,
body[dir="rtl"] .reverse.listItemTextContainerCategory,
body[dir="rtl"] #addChoiceOption.reverse .MuiButton-label,
body[dir="rtl"] .myCommentContainer.reverse,
body[dir="rtl"] .loginPageEmailLink.reverse {
  align-items: flex-start !important;
}

body[dir="rtl"] .reverse.categoryListItemContainer,
body[dir="rtl"] .commentNameLargeContainer.reverse,
body[dir="rtl"] .normalCardActionButtons.reverse,
body[dir="rtl"] .headingWithImgContainer.reverse,
body[dir="rtl"] .userPillContainer.reverse,
body[dir="rtl"] .commentAndDivider.reverse,
body[dir="rtl"] .editSubjectCheckBoxContainer.reverse .MuiFormControlLabel-root,
/* body[dir="rtl"] .reverse .arena-dropdown__control, */
body[dir="rtl"] .reverse .arena-select__control,
body[dir="rtl"] #conclusionHeadingContainer.reverse,
body[dir="rtl"] .dropdownContainer.reverse .MuiFormControlLabel-root,
body[dir="rtl"] .commentNameContainer.reverse,
body[dir="rtl"] .messagListItem.reverse,
body[dir="rtl"] #messageDateContainer.reverse,
body[dir="rtl"] .editSubjectCheckBoxContainer.reverse,
body[dir="rtl"] .searchPageCardContainer.reverse {
  flex-direction: row !important;
}
body[dir="rtl"] .reverse.commentNameLargeText .MuiTypography-body1,
body[dir="rtl"] .reverse .arena-dropdown__option,
body[dir="rtl"] .reverse .arena-select__menu-list,
body[dir="rtl"] .textAlignEnd.reverse,
body[dir="rtl"] .commentNameText.reverse .MuiTypography-body1,
body[dir="rtl"] .categoryHeaderTitle {
  text-align: start !important;
}

body[dir="rtl"] .clientsRootListContainer.reverse {
  align-items: flex-end !important;
}

body[dir="rtl"] .textAlignEnd.reverse {
  text-align: start !important;
}

body[dir="rtl"] .dropDownIconsContainer.reverse,
body[dir="rtl"] #readMoreAndIcon .MuiButton-label {
  justify-content: flex-end !important;
}

/* RTL ends here  */

body[dir="ltr"] .reverse.displayFlexEnd,
body[dir="ltr"] .reverse.loginPageEmailLink,
body[dir="ltr"] #headerInfoContainer.reverse,
body[dir="ltr"] #readMoreAndIcon.reverse,
body[dir="ltr"] #conclusionPageHeaderHeading.reverse,
body[dir="ltr"] #filterContainer.reverse,
body[dir="ltr"] .editSubjectButtonContainer.reverse,
body[dir="ltr"] #resetPasswordPageEmailLink.reverse {
  justify-content: flex-end !important;
}

body[dir="ltr"] .reverse.displayFlex,
body[dir="ltr"] .reverse.sendButton .MuiButton-label,
body[dir="ltr"] #backToCategoriesButton.reverse .MuiButton-label,
body[dir="ltr"] .categoryActiveDiscussionContainer.reverse,
body[dir="ltr"] .discussCardShareContainer.reverse,
body[dir="ltr"] #createCategoriInputFieldContainer.reverse,
body[dir="ltr"] #createCategoriUploaderContainer.reverse,
body[dir="ltr"] .categoryDiscussHeaderInfo.reverse,
body[dir="ltr"] .discussSubjectCommentsContainer.reverse,
body[dir="ltr"] #commentFilterMainContainer.reverse,
body[dir="ltr"] .commentComActionButtons.reverse,
body[dir="ltr"] .displayFlex.reverse,
body[dir="ltr"] .discussSubjecHeaderInfo.reverse,
body[dir="ltr"] #conclusionPageContainer.reverse,
body[dir="ltr"] #allocationPageChartMainContainer.reverse,
body[dir="ltr"] .allocationLegendContainer.reverse,
body[dir="ltr"] .editSubjectTextAndDropDownContainer.reverse,
body[dir="ltr"] .dropdownContainer.reverse .MuiFormGroup-root,
body[dir="ltr"] .dropDownIconsContainer.reverse,
body[dir="ltr"] #imageUploaderContainer.reverse,
body[dir="ltr"] #userControlPanelContainer.reverse,
body[dir="ltr"] .myCommentCountContainer.reverse,
body[dir="ltr"] .reverseMessageActionButton,
body[dir="ltr"] #updateModalActionContainer.reverse,
body[dir="ltr"] .changePasswordButton.reverse .MuiButton-label,
body[dir="ltr"] #shareModalButtonContainer.reverse,
body[dir="ltr"] .reverse .arena-select__control {
  flex-direction: row !important;
}

body[dir="ltr"] .categoryListContainer.reverse,
body[dir="ltr"] .reverse.listItemTextContainerCategory,
body[dir="ltr"] #addChoiceOption.reverse .MuiButton-label,
body[dir="ltr"] .myCommentContainer.reverse,
body[dir="ltr"] .loginPageEmailLink.reverse {
  align-items: flex-end !important;
}

body[dir="ltr"] .reverse.categoryListItemContainer,
body[dir="ltr"] .commentNameLargeContainer.reverse,
body[dir="ltr"] .normalCardActionButtons.reverse,
body[dir="ltr"] .headingWithImgContainer.reverse,
body[dir="ltr"] .userPillContainer.reverse,
body[dir="ltr"] .commentAndDivider.reverse,
body[dir="ltr"] .editSubjectCheckBoxContainer.reverse .MuiFormControlLabel-root,
body[dir="ltr"] #conclusionHeadingContainer.reverse,
body[dir="ltr"] .dropdownContainer.reverse .MuiFormControlLabel-root,
body[dir="ltr"] .commentNameContainer.reverse,
body[dir="ltr"] .messagListItem.reverse,
body[dir="ltr"] #messageDateContainer.reverse,
body[dir="ltr"] .editSubjectCheckBoxContainer.reverse,
body[dir="ltr"] .activeDiscussionDeadlineContainer,
body[dir="ltr"] #readMoreAndIcon {
  flex-direction: row-reverse !important;
}
body[dir="ltr"] .reverse.commentNameLargeText .MuiTypography-body1,
body[dir="ltr"] .commentNameText.reverse .MuiTypography-body1 {
  text-align: end !important;
}

body[dir="ltr"] .reverse .arena-dropdown__option,
body[dir="ltr"] .reverse .arena-select__menu-list,
body[dir="ltr"] .richTextFieldContainer .public-DraftStyleDefault-block,
body[dir="ltr"] .categoryHeaderTitle {
  text-align: start !important;
}
body[dir="ltr"] .reverse .arena-dropdown__value-container,
body[dir="ltr"] .reverse .arena-select__value-container {
  justify-content: flex-start !important;
}

body[dir="ltr"] .clientsRootListContainer.reverse {
  align-items: flex-start !important;
}

body[dir="ltr"] .dropDownIconsContainer.reverse,
body[dir="ltr"] #readMoreAndIcon .MuiButton-label,
body[dir="ltr"] #readMoreAndIcon {
  justify-content: flex-start !important;
}

body[dir="ltr"] .flexDirectionRowLTR {
  flex-direction: row-reverse !important;
}

body[dir="rtl"] .flexDirectionRowRTL {
  flex-direction: row-reverse !important;
}

body[dir="ltr"] #changePasswordUpdateModalButton.marginRight30 {
  margin-right: 0 !important;
  margin-left: 30px !important;
}

body[dir="rtl"] #updateModalSaveButton {
  margin-right: 0 !important;
  margin-left: 10px !important;
}

body[dir="rtl"] #createCategoryRightArenaButton {
  padding-left: 0 !important;
  padding-right: 52px !important;
}

body[dir="rtl"] #createCategoryLeftArenaButton {
  padding-right: 0 !important;
  padding-left: 52px !important;
}

body[dir="rtl"] #deleteButtonContainer {
  margin-right: 0 !important;
  margin-left: 50px !important;
}

body[dir="ltr"] #radioButtonSubjects .MuiFormGroup-root {
  justify-content: flex-start !important;
}

body[dir="ltr"] .editSubjectCheckBoxContainer .MuiFormControlLabel-root {
  justify-content: flex-end;
}
body[dir="ltr"] #conclusionHeadingContainer {
  justify-content: flex-start !important;
}

body[dir="ltr"] .arenaPagination .MuiPagination-ul {
  flex-direction: row-reverse;
}
body[dir="rtl"] .conclusionMainContainer {
  align-items: flex-start;
}

body[dir="rtl"] .actionButtonSpacing {
  margin-left: 8px !important;
  margin-right: 0 !important;
}

body[dir="rtl"] .justifyContentFlexStartRTL {
  justify-content: flex-start !important;
}

body[dir="rtl"] #startDatePickerContainer {
  margin-right: 0 !important;
}
body[dir="rtl"] #endDatePickerContainer {
  margin-right: 80px !important;
}

body[dir="rtl"] .toolbarClassName .borderRight {
  border-right: 0 !important;
}
body[dir="rtl"] .richTextFieldContainer .public-DraftStyleDefault-block {
  text-align: right !important;
}
body[dir="ltr"] .richTextFieldContainer .public-DraftStyleDefault-block {
  text-align: left !important;
}

body[dir="rtl"] .dropdownContainer .arena-dropdown__single-value > div {
  display: flex !important;
  flex-direction: row-reverse !important;
}

body[dir="rtl"] .directionRTL {
  direction: rtl !important;
}
body[dir="ltr"] #termsContainer .marginRight8 {
  margin-right: 0 !important;
  margin-left: 8px !important;
}
