/* Only mobile starts */
@media only screen and (min-width: 300px) and (max-width: 767px) {
  body[dir="rtl"] .categoryActiveDiscussionContainer.reverse,
  body[dir="rtl"] #createCategoriInputFieldContainer.reverse,
  body[dir="rtl"] #createCategoriUploaderContainer.reverse,
  body[dir="rtl"] #conclusionPageContainer.reverse,
  body[dir="rtl"] .allocationFieldsContainer.reverse {
    flex-direction: column-reverse !important;
  }
  body[dir="rtl"] #flexRespColRev {
    flex-direction: column-reverse !important;
  }
  body[dir="rtl"] #allocationPageChartMainContainer.reverse,
  body[dir="rtl"] .editSubjectTextAndDropDownContainer.reverse {
    flex-direction: column !important;
  }

  body[dir="ltr"] .categoryActiveDiscussionContainer.reverse,
  body[dir="ltr"] #conclusionPageContainer.reverse {
    flex-direction: column-reverse !important;
  }

  body[dir="ltr"] #conclusionPageContainer.insideSticky {
    align-items: flex-end !important;
  }
  body[dir="rtl"] #endDatePickerContainer {
    margin-right: 0 !important;
  }
  body[dir="ltr"] .editSubjectTextAndDropDownContainer.reverse,
  body[dir="ltr"] .allocationFieldsContainer.reverse {
    flex-direction: column !important;
  }
  body[dir="rtl"] .reverse.commentNameLargeText .MuiTypography-body1 {
    text-align: center;
  }
}
/* Only mobile ends */

@media only screen and (min-width: 300px) and (max-width: 1024px) {
  body[dir="rtl"] .commentNameLargeContainer.reverse,
  body[dir="ltr"] .commentNameLargeContainer.reverse {
    flex-direction: column !important;
  }
  body[dir="rtl"]
    .categoryDiscussionPageTopContent
    .commentNameLargeContainer
    .commentNameLargeText
    .MuiTypography-body1 {
    text-align: center !important;
  }

  body[dir="ltr"]
    .categoryDiscussionPageTopContent
    .commentNameLargeContainer
    .commentNameLargeText
    .MuiTypography-body1 {
    text-align: center !important;
  }

  body[dir="rtl"] .reverse.commentNameLargeText .MuiTypography-body1 {
    text-align: center !important;
  }
  body[dir="ltr"] .reverse.commentNameLargeText .MuiTypography-body1 {
    text-align: center !important;
  }
}
