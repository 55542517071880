.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  background-color: #1a1a1a !important;
  height: 100vh;
  font-family: "Rubik" !important;
}

#root {
  height: 100%;
}
.logo {
  width: 240px;
}
.headingImg {
  width: 80px !important;
  height: 80px !important;
}
.zIndex {
  z-index: 1 !important;
}

/* .MuiButtonBase-root{
  outline: none !important;
} */

.primaryBackground {
  background: #262833;
}

.facebookColor {
  background: #3b5998;
}

.controlPanelMainContainer {
  border: 1px solid #ffffff;
  border-radius: 30px;
  width: 40%;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  z-index: 100;
}
.diplayFlex {
  display: flex;
}
.arenaDarkBlack {
  background: #1a1a1a;
}
.whiteColor {
  color: white !important;
}
.dullWhite {
  color: #ece9f1 !important;
}
.dullerWhite {
  color: #a8a8a8 !important;
}

.blueColor {
  color: #65d2fc !important;
}
.greenColor {
  color: #4bd258;
}
.redColor {
  color: #fd4f42;
}
.bold {
  font-weight: 600 !important;
}
.textAlignEnd {
  text-align: end;
}
.textAlignCenter {
  text-align: center !important;
}
.marginTopBottom {
  margin-top: 10px !important;
}
.marginRight16 {
  margin-right: 16px !important;
}
.marginLeft16 {
  margin-left: 16px;
}
.marginBottom16 {
  margin-bottom: 16px !important;
}
.marginBottom8 {
  margin-bottom: 8px !important;
}
.marginRight8 {
  margin-right: 8px !important;
}

.marginTop24 {
  margin-top: 24px !important;
}
.commonBottomContainer {
  flex: 1;
  /* max-height: 100% */
}
.flexJustifyCenter {
  display: flex;
  justify-content: center;
  width: 100%;
}
/* .controlPanelMainContainer .MuiButtonBase-root{
  outline: none;
} */
.arenaInputField {
  background: #262833;
  border-radius: 8px;
  width: 100%;
}
.WAMuiChipInput-inputRoot-574.WAMuiChipInput-outlined-577 {
  padding-top: 2px !important;
}

/* .MuiOutlinedInput-notchedOutline {
  border-color: rgb(255, 255, 255);
} */
.arenaChipInput {
  border: 1px solid #9a9a9a !important;
}
.MuiChip-deleteIcon {
  color: rgb(101, 210, 252) !important;
}
.MuiChip-root {
  color: #fff !important;
  background-color: #1f2429 !important;
  border: rgb(101, 210, 252) 2px solid !important;
}
.arenaInputField .MuiInputBase-input {
  color: white !important;
  padding: 14px !important;
  border-radius: 8px !important;
}
.arenaInputField .MuiOutlinedInput-root {
  border-radius: 8px !important;
  /* outline: none; */
}
.arenaInputField.Mui-focused {
  border-color: white !important;
}
.shareAndMsgContainer {
  margin: 0px 8px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shareAndMsgButton {
  padding: 0 !important;
  margin-bottom: 8px !important;
}
.successContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.categoryListItemContainer {
  flex-direction: row-reverse;
  padding-right: 0 !important;
  border-radius: 15px !important;
  padding-top: initial !important;
}

.categoryListItemContainer:hover,
.categoryListItemContainer:focus {
  background: linear-gradient(#424141, transparent);
}
.categoryListItemText {
  flex: none !important;
  text-align: start;
  margin-right: 10px;
  color: white;
  font-weight: 600 !important;
  font-size: 22px !important;
  text-transform: initial !important;
}
.commentNameText .MuiTypography-body1 {
  flex: none !important;
  text-align: end;
  margin-right: 10px;
  color: white;
  font-weight: 700 !important;
  font-size: 20px !important;
}
.commentNameLargeText .MuiTypography-body1 {
  flex: none !important;
  text-align: end;
  margin-right: 10px;
  color: white;
  font-weight: 700 !important;
  font-size: 36px !important;
}
.messageListItemText {
  color: white;
  /* flex: none !important; */
}
.categoryListItemAvatar {
  width: 90px !important;
  height: 90px !important;
}
.lineHeightNone {
  line-height: 0.4 !important;
}
.lineHeightOne {
  line-height: 1.1 !important;
}
.listItemTextContainerCategory {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.listItemTextContainerCategory .MuiListItemText-primary {
  font-weight: 600 !important;
  font-size: 22px !important;
}

.commentNumberSubContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sendButtonBorderRadius .MuiButton-label {
  margin-top: 2px !important;
}

.sendButtonBorderRadius .MuiButton-startIcon.MuiButton-iconSizeSmall {
  margin-top: -2.5px !important;
}

.sendButtonBorderRadius.MuiButton-outlined.Mui-disabled {
  border-color: #9a9a9a !important;
}

.sendButtonBorderRadius.MuiButton-root.Mui-disabled {
  color: #9a9a9a !important;
}

.sendButtonBorderRadius {
  border-radius: 25px !important;
  border-color: #65d2fc !important;
  border: 1.5px solid !important;
  width: 100px !important;
}
.sendButtonBorderRadius:active {
  background: #65d2fc !important;
}
.sendButtonBorderRadius:active .MuiButton-label {
  color: black !important;
}
.sendButtonBorderRadius:hover {
  background: #65d2fc !important;
}
.sendButtonBorderRadius:hover .MuiButton-label {
  color: black !important;
}
.sendButtonBorderRadius:focus {
  border: 1.5px solid !important;
  background: initial !important;
}
.sendButtonBorderRadius:focus .MuiButton-label {
  color: #65d2fc !important;
}

.facebookColor {
  border-color: #3b5998;
  background: #3b5998 !important;
}
.facebookColor:hover {
  background: #597dc9 !important;
}

.googleColor {
  border-color: #dd4b39;
  background: #dd4b39 !important;
}
.googleColor:hover {
  background: #ed7264 !important;
}

.socialButtonBorderRadius {
  border-radius: 25px !important;
  border: 1.5px solid;
  width: 140px;
  height: 36px;
  /* outline: none !important; */
  z-index: 9;
}
.socialButtonBorderRadius:focus {
  border: 2px solid white;
}
.socialButtonBorderRadius .MuiButton-label {
  display: flex;
  justify-content: center;
  padding: 3px 0;
}
.socialButtonBorderRadius .MuiButton-startIcon {
  margin-right: 0 !important;
}
.sendButtonBorderRadius .MuiButton-label {
  font-size: 11px !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center !important;
}
.socialButtonBorderRadius .material-icons {
  font-size: 15px !important;
}
.sendButtonBorderRadius .MuiButton-startIcon {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.sendButtonBorderRadius .MuiButton-iconSizeSmall > *:first-child {
  font-size: 12px !important;
}
.userPillContainer {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.commentComActionButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 24px;
}
.commentMainContainer {
  /* padding-right: 30px;
  width: 50%; */
}
.subCommentPadding {
  padding-right: 40px !important;
  margin-top: 16px;
}
.subCommentDivider {
  height: auto !important;
  margin-left: 0 !important;
  background: #e0e0e0 !important;
  margin-right: 0 !important;
  margin-left: 8px !important;
}
.commentAndDivider {
  display: flex;
  flex-direction: row-reverse;
}
#messageDateContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-right: 16px;
}
.commentIconButton {
  padding: 4px !important;
}
.commentActionText {
  font-size: 11px !important;
}
.commentActionCount {
  font-size: 24px !important;
  line-height: 0.8;
}
.flexColumnCenter .CircularProgressbar {
  width: 60px !important;
  margin-bottom: 6px;
}
.flexColumnCenter .CircularProgressbar .CircularProgressbar-text {
  fill: white !important;
  font-size: 26px !important;
}
.flexColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: 6px 12px;
}

#addChoiceOption {
  cursor: pointer;
  margin-bottom: 16px;
  align-items: flex-end;
}
#addConclusionOption {
  cursor: pointer;
  margin-bottom: 16px;
  margin-top: 16px;
}

.arenaPagination .MuiPaginationItem-root {
  color: #fff;
  opacity: 0.38;
}
.arenaPagination .MuiPaginationItem-root .MuiPaginationItem-icon {
  color: #65d2fc !important;
}

.arenaPagination .MuiPaginationItem-page.Mui-selected {
  color: white;
  background: none !important;
  opacity: 1 !important;
}
.arenaPagination .MuiPaginationItem-page.Mui-disabled {
  opacity: 1 !important;
}
.conclusionMainContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
#paginationDownloadContainer {
  display: flex;
  align-items: center;
  margin-top: 100px;
}
.conclusionContentImg {
  display: flex;
  margin: 16px 0;
  justify-content: center;
}
.conclusionTitleExtra {
  margin: 10px 0 !important;
}
.categoryDiscussCardContainer {
  border-radius: 5px;
  position: relative;
  /* height: 300px; */
  /* max-width: 35%; */
}
.discussCardImgContainer {
  max-height: 110px;
  position: relative;
}

.discussCardImgContainer:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 133%;
  background-image: linear-gradient(
    rgba(26, 26, 26, 0) 0%,
    rgba(26, 26, 26, 0) 0%,
    /* // These three 'smooth' out the fade. */ rgba(26, 26, 26, 0) 66%,
    rgba(26, 26, 26, 0.2) 71%,
    rgba(26, 26, 26, 0.5) 75%,
    /* // Solid to match the background. */ rgba(26, 26, 26, 1) 100%
  );
}
.categoryDiscussDescription {
  margin-top: 10px;
}
.discussCardshareContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  padding: 0 56px;
}
.normalDiscussCard {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: flex-end;
  margin-top: 10px;
}
.discussCardButton {
  display: flex;
  justify-content: center;
}
.discussRowReverse {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: space-evenly;
  margin-top: 8px;
}
.activeDiscussionActionContainer {
  margin-top: 8px;
}
.dateComponentContainer {
  display: flex;
}
.dateSubContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dateComponentBullet {
  font-size: 35px;
  margin: 0 8px;
  color: white;
}
.activeDiscussionDeadlineContainer {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}
.activeDiscussDateCountdown {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.selectedOption {
  border: 1px dashed #65d2fc !important;
  border-radius: 15px;
}
.multiSelectSub {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0px;
  border-radius: 15px;
}

.multiSelectSub:hover {
  background: linear-gradient(#424141, transparent);
}

.commentNameContainer {
  flex-direction: row-reverse;
  padding-right: 0 !important;
}
.commentNameContainer .MuiAvatar-root {
  margin-right: 0 !important;
  width: 36px;
  height: 36px;
}
.commentNameContainer .MuiListItemAvatar-root {
  min-width: 0 !important;
}

.commentNameLargeContainer {
  flex-direction: row-reverse;
  padding-right: 0 !important;
}

.commentNameLargeContainer .MuiAvatar-root {
  margin-right: 0 !important;
  width: 100px;
  height: 100px;
}

.commentNameLargeContainer .MuiListItemAvatar-root {
  min-width: 0 !important;
}

.myCommentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.myCommentCountContainer {
  display: flex !important;
  justify-content: flex-end !important;
}
.arenaPassworField .MuiFormControl-root {
  background: #262833;
  border-radius: 8px;
  width: 100%;
  direction: rtl;
}

.arenaPassworField .MuiOutlinedInput-root {
  border-radius: 8px;
  /* outline: none; */
}

.arenaPassworField .outlined-adornment-password {
  color: white !important;
  opacity: 0.8 !important;
}

.arenaPassworField .MuiSvgIcon-root {
  color: white;
  opacity: 0.5;
}
.discussCardContent {
  position: relative;
  margin-top: 2px;
}
.activeDiscussionCarousel .carousel {
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  align-items: center;
}
.blackColor {
  background: black;
}

.activeDiscussionCarousel .carousel-indicators {
  position: initial !important;
  /* margin-right: 0 !important; */
  /* margin-left: 233px; */
  padding: 0 !important;
}

.activeDiscussionCarousel .carousel-inner {
  /* display: flex; */
}

.activeDiscussionCarousel .carousel-indicators li {
  width: 5px !important;
  height: 5px !important;
  background-color: #65d2fc !important;
  border-radius: 50%;
}
/* .activeDiscussionCarousel .carousel-control-prev {
  display: none;
}

.activeDiscussionCarousel .carousel-control-next {
  display: none;
} */
.activeDiscussionCarousel {
  margin-top: 16px;
}

.categoryDiscussDescription {
  margin-top: 10px;
  padding-left: 16px;
  height: 80px;
  overflow-y: scroll;
  scrollbar-width: none;
}
.descriptionStaticHeight {
  max-height: 80px;
  overflow-y: scroll;
  min-height: 80px;
  scrollbar-width: none;
}
.landingPageMainContainer {
  width: 45%;
  position: relative;
  top: -92px;
}
#LandingPageContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#highlightedTextContainer {
  margin-top: 40px;
}
#paraOneLandingPage {
  margin-top: 30px;
  margin-bottom: 20px;
}
.allocationLegendBox {
  width: 22px;
  height: 22px;
  margin-left: 14px;
}
.allocationLegendContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* margin: 30px 0; */
}
.popoverButtonContainer {
  text-transform: initial !important;
}
.popoverButtonContainer .MuiButton-label {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  flex: 1 !important;
}
.popoverIconButton {
  padding: 0 !important;
  margin-bottom: 8px !important;
}
.arenaPopover .popover-inner {
  background: #262833;
  border-radius: 10px;
}
.arenaPopover .bs-popover-auto[x-placement^="left"] > .arrow::before,
.bs-popover-left > .arrow::before {
  border-left-color: #fff !important;
}
#userControlPanelContainer {
  display: flex;
  justify-content: space-around;
}

.messagListItem {
  flex-direction: row-reverse;
}

.messagListItem .MuiAvatar-root {
  width: 26px;
  height: 26px;
}
.messagListItem .MuiListItemAvatar-root {
  min-width: 0 !important;
  margin-top: 12px;
}
#messageListItemDivider,
.commentListDivider,
.myCommetnListItemDivider {
  background-color: #ffffff !important;
  width: 100% !important;
  height: 1px !important;
  opacity: 0.2 !important;
}
.myCommetnListItemDivider {
  margin-bottom: 8px !important;
  opacity: 0.1 !important;
}
.commentListDivider {
  margin: 12px 0 !important;
  opacity: 0.1 !important;
}
#messagesControlPanelLoadMore {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
#messagesLinkTag,
#redirectLinkTag {
  text-decoration: underline;
  color: #65d2fc;
  cursor: pointer;
  font-size: 16px;
}
#redirectLinkTag {
  font-size: 16px;
  text-decoration: none;
}
#commentFilterText {
  color: #ece9f1;
  text-align: end;
}
.commonStructureContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: calc(100% - 40px); */
  /* overflow-y: scroll;
  overflow-x: hidden; */
}
.loginToSystemText {
  /* margin-top: 85px; */
  margin-bottom: 20px;
}

.loginDivider {
  margin: 6px 0 !important;
  background-color: white !important;
  height: 1px !important;
}
.loginPageEmailLink,
#resetPasswordPageEmailLink,
#loginPageRedirectLink {
  margin-top: 26px;
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
}
#loginPageRedirectLink {
  justify-content: center !important;
  margin-top: 42px;
  padding-right: initial;
}
#resetPasswordPageEmailLink {
  padding-right: 0 !important;
}
.loginInputFieldContainer,
.changePasswordInputFieldContainer {
  display: flex !important;
  justify-content: center !important;
  margin-top: 26px !important;
}
#loginSendButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.loginSignupOverlap {
  position: relative;
  top: -24px;
  margin-bottom: 80px;
  width: 40%;
}
.loginDividerContainer {
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
.flexCenter {
  display: flex;
  justify-content: center;
}
.loginInfoTextMargin {
  margin-top: 20px;
  margin-bottom: 20px;
}
.signupLoginTopContent,
.categoriesTopContent,
.conclusionPageTopContent,
.myCommentPageTopContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  /* margin-top: 130px; */
}

.categoriesTopContent {
  width: 80%;
}
.conclusionPageTopContent {
  justify-content: center !important;
}
.clientsRootTopContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* margin-top: 15px; */
  justify-content: center;
}
.editClientPageTopContent {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  align-items: center;
}
#editClientPageText {
  z-index: 9;
  display: flex;
  justify-content: center;
}
.editClientFieldText {
  margin-bottom: 16px;
}
.descriptionNameContainer,
.editProfileButtonsContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: baseline;
}

.editProfileEmailMargin {
  margin-right: 50px;
}

.editProfileNameMargin {
  margin-left: 50px;
}
.textAndFieldContainer {
  flex: 0.5;
}
.checkBoxContainer {
  margin: 26px 74px;
}

.checkBoxContainer .MuiFormControlLabel-root {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 0 !important;
}

.checkBoxContainer .MuiTypography-root {
  color: white;
}

.checkBoxContainer .MuiCheckbox-colorPrimary.Mui-checked {
  color: #65d2fc;
}

.checkBoxContainer .MuiCheckbox-root {
  color: white;
}

.editSubjectCheckBoxContainer .MuiFormControlLabel-root {
  display: flex !important;
  margin-right: 0 !important;
  justify-content: flex-start;
}

.editSubjectCheckBoxContainer .MuiTypography-root {
  color: white !important;
}

.editSubjectCheckBoxContainer .MuiCheckbox-colorPrimary.Mui-checked {
  color: #65d2fc !important;
}

.editSubjectCheckBoxContainer .MuiCheckbox-root {
  color: #65d2fc !important;
}

.editSubjectCheckBoxContainer {
  margin: 24px 0;
  display: flex;
}

#editProfileButton {
  display: flex;
  justify-content: flex-end;
}

.arenaInputField .MuiFormHelperText-root {
  background: #1a1a1a !important;
  margin: initial !important;
}

/* .loginInputFieldContainer .MuiOutlinedInput-root .Mui-error .Mui-focused{
  color: red !important;
} */

#editProfileLogoHeader {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 60px;
}

.clientsRootListContainer {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* flex-wrap: wrap; */
  /* flex-flow: wrap; */
  position: relative;
  top: -32px;
  flex-wrap: wrap-reverse;
  /* height: 82vh; */
}
.clientsRootListItemContainer {
  width: 35%;
  margin-bottom: 64px;
}

.clientsRootListItemContainer .MuiListItem-root {
  padding-top: initial !important;
}

.categoriesListItemContainer {
  width: 34%;
  z-index: 9;
  margin-bottom: 64px;
  cursor: pointer;
}
.categoryListContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  top: -32px;
}

.overlapBottom {
  position: relative;
  top: -4px;
}

.sendMessageTopContent,
.categoryDiscussionPageTopContent,
.discussSubjectPageTopContent {
  display: flex;
  align-items: center;
  width: 100%;
}

.sendMessageMarginBottom {
  margin-bottom: 26px;
}
.sendMessageMainContainer {
  width: 35%;
  position: relative;
  top: -32px;
}
#sendMessageHeading {
  margin-bottom: 36px;
  display: flex;
  justify-content: center;
}
#sendMessageButtonContainer .sendButtonBorderRadius {
  width: 116px !important;
  height: 36px !important;
}

.activeDiscussionCardContainer {
  /* width: 60%; */
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.categoryDiscussHeaderInfo,
.discussSubjecHeaderInfo {
  display: flex;
  align-items: flex-end;
  margin-top: 30px;
  width: 50%;
  justify-content: space-evenly;
}

.discussSubjecHeaderInfo {
  width: 28% !important;
  z-index: 1;
}

#headerInfoContainer {
  display: flex;
  justify-content: flex-end;
  z-index: 9;
}

.categoryActiveDiscussionContainer,
.categoryDiscussionCardContainer {
  display: flex;
  flex-direction: row;
}
.categoryDiscussionCardContainer {
  justify-content: space-between;
  flex-wrap: wrap;
}

.categoryDiscussionPageMainContainer {
  width: 55%;
}

#footerComponentContainer {
  display: flex;
  justify-content: center;
  background-color: #262833;
  padding: 8px;
  z-index: 111;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.subjectAnswereListContainer {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  margin: 36px auto;
  width: 80%;
}
.multiSelectSubjectContainer {
  width: 260px;
  margin: 20px 0;
  border: 1px dashed transparent;
  display: flex;
  justify-content: center;
}

.mainBackgroundImgContainer {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 40vh;
}

.resetPasswordOverlap {
  position: relative;
  top: -60px;
}

#loginSendButtonContainer .sendButtonBorderRadius {
  height: 36px !important;
  width: 112px !important;
}

.arena-dropdown__menu {
  min-width: 200px;
  z-index: 1500 !important;
  overflow: visible;
}

.arena-dropdown__menu-list {
  text-align: end;
  background: #262833 !important;
}
.arena-dropdown__control {
  display: flex;
  background: #262833 !important;
  height: 100%;
  border-radius: 8px !important;
  border: 1px solid #9a9a9a !important;
}
.arena-dropdown__value-container {
  justify-content: flex-end;
}
.arena-dropdown__menu-list {
  border: 1px solid #9a9a9a !important;
  border-radius: 8px;
}
.arena-dropdown__placeholder {
  color: #ffffff !important;
}
.arena-dropdown__option {
  color: #ffffff !important;
  background: #262833 !important;
}
.arena-dropdown__option:hover,
.arena-dropdown__option:focus,
.arena-dropdown__option:active,
.arena-dropdown__option:visited {
  background: #262833 !important;
  opacity: 0.5 !important;
}
.arena-dropdown__input #react-select-2-input {
  color: #ffffff !important;
}
.arena-dropdown__single-value {
  color: #ffffff !important;
}
.arena-dropdown__indicator-separator {
  display: none;
}
.arena-dropdown-container {
  height: 54px;
}

.arena-select__menu-list {
  text-align: end;
  background: #262833 !important;
}
.arena-select__control {
  display: flex;
  flex-direction: row-reverse;
  background: #262833 !important;
  border-radius: 8px !important;
  border: 1px solid #9a9a9a !important;
  min-height: 54px !important;
}
.arena-select__value-container {
  justify-content: flex-end;
}
.arena-select__indicator-separator {
  display: none;
}
.arena-select__menu-list {
  border: 1px solid #9a9a9a !important;
  border-radius: 8px;
}
.arena-select__placeholder {
  color: #ffffff !important;
}
.arena-select__option {
  color: #ffffff !important;
  background: #262833 !important;
}
.arena-select__option:hover,
.arena-select__option:focus,
.arena-select__option:active {
  background: #262833 !important;
  opacity: 0.5 !important;
}
.arena-select__input {
  color: #ffffff;
}
.arena-select__multi-value__remove {
  color: #ffffff;
}
.arena-select-container {
  height: 54px;
}
.arena-select__multi-value {
  display: flex;
  flex-direction: row-reverse;
}
.arena-select__multi-value {
  background: #557d7a !important;
}
.arena-select__multi-value__label {
  color: #ffffff !important;
}
.arena-select__clear-indicator {
  display: none !important;
}
.arenaDatePickerMainContainer {
  border: 1px solid #9a9a9a;
  padding: 8px 8px;
  border-radius: 8px;
  background: #262833;
}

.arenaDatePickerMainContainer .MuiFormControl-root {
  width: 100%;
}
.arenaDatePickerMainContainer .MuiInputBase-root {
  display: flex;
  flex-direction: row-reverse;
  color: #fff !important;
}

.arenaDatePickerMainContainer .date-picker-dialog {
  text-align: end !important;
  color: #ffffff !important;
}
.arenaDatePickerMainContainer .MuiInput-underline:before {
  display: none !important;
}
.arenaDatePickerMainContainer .MuiInput-underline:after {
  display: none !important;
}
.arenaDatePickerMainContainer .MuiIconButton-label {
  color: #ffffff !important;
}
.datePickerDialog .MuiPickersBasePicker-container,
.MuiPickersBasePicker-pickerView {
  background: #262833;
  color: #ffffff !important;
}
.datePickerDialog .MuiPickersCalendarHeader-dayLabel {
  color: #ffffff !important;
}
.datePickerDialog .MuiPickersDay-daySelected {
  background-color: #4bd258 !important;
}
.MuiPickersCalendar-week .MuiIconButton-label {
  color: #ffffff;
}
.MuiPickersCalendarHeader-iconButton {
  background-color: initial !important;
}
.MuiPickersCalendarHeader-switchHeader .MuiIconButton-label {
  color: #65d2fc;
}
.MuiPickersCalendar-week .MuiButtonBase-root:hover {
  background-color: #65d2fc;
  opacity: 0.4;
  /* color: white */
}

.MuiPickersCalendar-week .MuiPickersDay-day .MuiIconButton-label:hover {
  color: white;
}

#allocationPageReadMoreContainer {
  min-height: 130px !important;
}
.spaceBetweenLikeCount {
  margin-right: 40px;
}

.subjectFieldFormWidth {
  width: 30% !important;
}

#updateModalDialogContent {
  padding: 0 88px !important;
}

#changePasswordUpdateModalButton .MuiButton-label {
  font-size: 14px !important;
  min-height: 50px !important;
}

#updateModalSaveButton {
  padding: 18px !important;
}
#updateModalSaveButton .MuiButton-label {
  font-size: 14px !important;
}

/* ------------------------------------------------------------Responsiveness ------------------------------------*/
@media only screen and (min-width: 300px) and (max-width: 767px) {
  .controlPanelMainContainer {
    border: none !important;
    color: white;
    border-radius: 0 !important;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8vh;
    z-index: 1111;
  }
  .logo {
    width: 212px;
  }
  .commentNameLargeContainer .MuiAvatar-root {
    width: 85px !important;
    height: 85px !important;
  }
  /* #footerComponentContainer{
    display: none;
  } */
  #multiSubjectUserAndInfo {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .spaceBetweenLikeCount {
    margin-right: 12px !important;
  }
  .controlPanelIcon {
    font-size: 32px !important;
  }
  .clientsRootListItemContainer {
    width: 80% !important;
  }
  .clientsRootListContainer {
    top: -42px !important;
    width: 90% !important;
  }
  .resetPasswordOverlap {
    top: -28px !important;
    width: 90%;
  }

  .categoryListContainer {
    top: -14px;
    margin-bottom: 48px;
  }
  .categoryHeadingTypoExtra {
    font-size: 24px !important;
  }
  .activeDiscussionDeadlineContainer {
    margin-top: 16px;
  }
  .categoryDiscussionPageMainContainer {
    width: 94%;
    position: relative;
    top: 26px;
  }
  .categoryDiscussionCardContainer {
    flex-direction: column;
  }
  .categoryActiveDiscussionContainer {
    flex-direction: column-reverse;
  }
  .categoryDiscussDescription {
    display: none;
  }
  #headerInfoContainer {
    justify-content: center;
  }
  .categoryDiscussHeaderInfo {
    justify-content: space-between;
    width: 80%;
    margin-top: -14px;
    margin: auto;
  }
  .loginSignupOverlap {
    top: -44px !important;
    width: 100%;
  }
  .commentNameLargeContainer {
    padding-left: 0 !important;
  }
  .categoriesListItemContainer {
    width: 100%;
  }
  .categoriesListItemContainer .MuiListItem-root {
    padding-top: initial !important;
  }

  .clientsRootListItemContainer {
    width: 100% !important;
  }

  .commentNameLargeContainer {
    display: flex !important;
    flex-direction: column;
  }
  .landingPageMainContainer {
    width: 100% !important;
    padding: 0 36px;
    top: -48px !important;
  }
  .loginToSystemText {
    margin-bottom: 20px;
  }
  .signupSocialNetworkText {
    margin-top: 36px !important;
  }
  .descriptionNameContainer {
    flex-direction: column;
  }
  .editProfileEmailMargin {
    margin-right: 0;
  }
  .editProfileNameMargin {
    margin-left: 0;
    margin-top: 26px;
  }
  .editProfileButtonsContainer {
    justify-content: space-between;
  }
  .sendMessageMainContainer {
    width: 90%;
    top: 6px;
    margin-bottom: 60px;
  }
  .commentNameLargeText .MuiTypography-body1 {
    font-size: 30px !important;
    text-align: center;
    margin-right: inherit;
  }
  .commentNameLargeText {
    width: 100%;
  }
  .sendMessageTopContent .commentNameLargeContainer .MuiAvatar-root {
    margin-bottom: 24px;
  }
  .conclusionPageHeaderHeading {
    display: none !important;
  }
  .headingWithImgContainer .MuiTypography-root {
    font-size: 17px;
  }
  .conclusionPageConclusionContainer {
    display: flex;
    flex-direction: column-reverse;
  }

  .discussPageReadMoreContainer {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
  #multiSelectReadMoreComponent {
    display: none !important;
  }
  .multiSelectSubjectContainer {
    width: 100% !important;
  }
  .subjectAnswereListContainer {
    flex-direction: column !important;
    align-items: center !important;
  }
  .multiSelectMainContainer {
    width: 96% !important;
    top: 32px !important;
    z-index: 9;
  }
  #concusionPageBottomMainContainer {
    flex-direction: column !important;
    width: 90% !important;
    position: relative;
    top: 12px !important;
  }
  .conclusionMainContainer {
    margin-bottom: 26px;
  }
  .conclusionPageCommentContainer {
    margin-top: 26px;
    margin-bottom: 20px;
    width: 100%;
  }
  #conclusionPageNumberContainer {
    margin: auto !important;
  }
  .myCommentsPageMainContainer {
    width: 82% !important;
    top: -32px !important;
  }
  #discussSubjectHeadingImgContainer {
    display: none;
  }
  .discussSubjectCommentsContainer {
    flex-direction: column-reverse !important;
  }
  .discussSubjectMainContainer {
    width: 90% !important;
  }
  #discussSubjectUserpillContainer {
    margin-top: 26px;
  }
  .discussSubjecHeaderInfo {
    margin-top: initial !important;
    width: 80% !important;
    margin: auto;
  }
  .discussSubjectMainContainer {
    position: relative !important;
    top: 32px !important;
  }
  .footerComponentContainer {
    display: none;
  }
  #allocationPageChartMainContainer {
    flex-direction: column !important;
  }
  #allocationPageReadMoreContainer {
    display: none;
  }

  #createCategoriInputFieldContainer {
    flex-direction: column-reverse;
  }
  #createCategoriUploaderContainer {
    flex-direction: column-reverse;
  }
  .socialShareModal .MuiPaper-root {
    min-width: 350px !important;
  }
  .createCategorieBottomMainContainer {
    margin-bottom: 18px;
    width: 90% !important;
  }
  #createCategoryRoundUploader {
    width: 50% !important;
    margin: auto !important;
  }
  #createCategoriButtonContainer {
    justify-content: space-between !important;
  }
  .checkBoxContainer {
    margin: 26px 12px;
  }
  /* .multilineTextFieldContainer{
    width: 100% !important;
  } */
  .fieldWidth {
    width: 90% !important;
  }
  .loginPageEmailLink {
    padding-right: 24px !important;
  }
  .discussPageReadMoreContainer.commentConclusionFlexContainer {
    position: inherit !important;
  }
  .insideSticky .discussTitleExtra,
  .insideSticky #filterContainer {
    position: inherit !important;
  }
  .zIndex1Mobile {
    z-index: 1 !important;
  }

  .fullWidthMobile {
    width: 95vw !important;
  }

  #allocationSendButton {
    margin-bottom: 80px !important;
  }
  #conclusionSwipeable,
  #conclusionPageNumberContainer {
    max-width: 330px !important;
  }
  #concusionPageBottomMainContainer {
    width: 95% !important;
  }
  .insideSticky
    .conclusionPageConclusionContainer.commentConclusionFlexContainer {
    position: inherit !important;
  }
  .insideSticky
    .conclusionPageConclusionContainer.commentConclusionFlexContainer {
    position: inherit !important;
  }
  .conclusionPageTopContent.positionSticky {
    z-index: 1500 !important;
    top: 0 !important;
    /* background-color: #1a1a1a !important; */
  }
  .insideSticky .conclusionTitleExtra {
    position: inherit !important;
  }
  .subjectFieldFormWidth {
    width: 100% !important;
  }
  .editSubjectTextContainer {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .richTextFieldContainer {
    width: 100% !important;
  }
  .multilineTextFieldContainer {
    width: 100% !important;
  }
  .datePickerContainer {
    flex-direction: column !important;
  }
  .allocationConstantField {
    width: 100% !important;
  }
  .editSubjectDropdownContainer {
    margin-left: 0 !important;
  }
  #categoryDiscussionTopContainer {
    width: 90% !important;
  }

  #updateModalDialogContent {
    padding: 0 22px !important;
  }
  .changePasswordModal .MuiPaper-root {
    max-width: 350px !important;
    min-width: 0 !important;
  }
  /* #changePasswordUpdateModalButton .MuiButton-label {
    font-size: 10px !important;
  } */
  #updateModalSaveButton {
    padding: 14px !important;
  }

  #updateModalSaveButton .MuiButton-label {
    font-size: 10px !important;
  }
  .arenaPopover .bs-popover-auto[x-placement^="top"] {
    left: -10px !important;
  }
  .searchPageMainContainer {
    width: 100% !important;
    padding: 0 12px;
    position: relative;
    top: -56px;
  }
  .successPageCardContainer {
    margin-right: 0 !important;
    width: 100% !important;
  }
  #searchComponentContainer {
    min-width: 280px !important;
  }
  /* .search .bs-popover-auto[x-placement^="top"] {
    left: -45px !important;
  } */
  .arenaPopover .popover {
    width: 100%;
    border-radius: 0px !important;
    border: none !important;
  }
  .arenaPopover .popover-inner {
    border-radius: 0px !important;
  }
  #shareModalButtonContainer {
    padding: 0 12px 50px 12px !important;
  }
  .discussSubjectTopMainContainer {
    overflow: hidden;
  }
  #allocationSendButton .sendButton {
    width: 100% !important;
  }
  #messagesContainer {
    margin: auto !important;
  }
  #userControlPanelComponentContainer {
    width: 100% !important;
  }
  #footerComponentContainer {
    display: none;
  }
  /* .mainBackgroundImgContainer {
    height: initial !important;
  } */
  .showFullText {
    max-height: initial !important;
  }
  #discussSubjectKindMobile {
    position: sticky;
    top: 0;
    /* background-color: #1a1a1a; */
    z-index: 1 !important;
    padding-top: 4px;
  }
  /* #commonStructureTopContent.zIndex1{
    z-index: inherit !important;
  } */

  .backgroundImageHeight {
    height: 40vh !important;
  }
  #topContainerContent.mainBackgroundImgContainer {
    height: 40vh !important;
  }
  #conclusionPageContainer {
    z-index: 0;
    width: 100%;
  }
  .myCommentPageTopContent {
    height: 40vh !important;
  }
  .commentComActionButtons {
    justify-content: space-evenly;
  }
  .categoriesTopContent {
    width: 100%;
  }
  .notFoundImage {
    margin: 32px 0;
    max-width: 90% !important;
  }
  .notFoundMainContainer {
    max-width: 90% !important;
    text-align: center;
  }
  .notFoundTopContent {
    height: 40vh !important;
  }
  .emptyConclusionContainer {
    width: 90vw !important;
    text-align: center;
  }

  #removeButtonContainer {
    position: initial !important;
  }
  .editSubjectCheckBoxContainer {
    display: flex;
    flex-direction: column;
  }
  .commentsActionButtons {
    flex: 1;
    justify-content: space-evenly;
  }
  .editSubjectBottomMainContainer,
  .createCategorieBottomMainContainer {
    top: -82px !important;
  }
  .discussSubjectCommentsListContainer .commentComActionButtons {
    justify-content: space-between !important;
  }
}

/* Desktop */
@media only screen and (min-width: 1025px) {
  .controlPanelMainContainer {
    border: 1.5px solid #ffffff;
    border-radius: 30px;
    width: 46px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: fixed;
    right: 16px;
    align-items: center;
    top: 100px;
  }

  /* #footerComponentContainer {
    z-index: 111;
  } */
  .discussSubjectTopMainContainer,
  .editSubjectTopMainContainer {
    width: 80% !important;
  }
  #editProfileMainContainer {
    position: relative;
    top: -130px;
  }
  /* .conclusionCommentsListContainer,
  .discussSubjectCommentsListContainer {
    height: 77vh;
    overflow-y: scroll;
  } */

  .conclusionCommentsListContainer:before {
    content: "";
    display: inline-block;
    position: relative;

    /* z-index: 1; */
    background-image: linear-gradient(
      rgba(26, 26, 26, 0) 0%,
      rgba(26, 26, 26, 0) 0%,
      /* // These three 'smooth' out the fade. */ rgba(26, 26, 26, 0) 60%,
      rgba(26, 26, 26, 0.3) 78%,
      rgba(26, 26, 26, 0.4) 83%,
      /* // Solid to match the background. */ rgba(26, 26, 26, 1) 101%
    );
  }
  .controlPanelIcon {
    font-size: 26px !important;
  }
  .headingImg {
    width: 92px !important;
    height: 92px !important;
  }
  .commonStructureTopContent {
    height: 55vh !important;
  }

  .clientsRootTopContent {
    margin-top: -30px !important;
    z-index: 9;
  }
  .activeDiscussionCarousel {
    margin-top: 6px;
  }
  .signupLoginTopContent,
  .categoriesTopContent,
  .conclusionPageTopContent,
  .myCommentPageTopContent,
  .sendMessageTopContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    margin-top: -20px !important;
    z-index: 9;
  }

  .conclusionPageTopContent {
    margin-top: 0 !important;
  }

  .activeCardAndHeading {
    position: relative;
    top: -35px;
  }
  #discussSubjectKindMobile {
    display: none !important;
  }

  .discussSubjectCommentsListContainer:before {
    width: 48% !important;
  }

  .categoryDiscussHeaderInfo {
    width: 28% !important;
  }
  .discussSubjecHeaderInfo {
    width: 30% !important;
  }

  .discussSubjectCommentsListContainer:before {
    /* content: ""; */
    display: inline-block;
    position: absolute;
    width: 38%;
    height: 75%;
    /* z-index: 1; */
    /* background-image: linear-gradient(
      rgba(26, 26, 26, 0) 0%,
      rgba(26, 26, 26, 0) 0%,
      rgba(26, 26, 26, 0.3) 78%,
      rgba(26, 26, 26, 0.4) 83%,
    ); */
  }

  #LandingPageContentContainer {
    position: relative;
    top: -60px;
  }
  #createCategoryRoundUploader {
    width: 150px;
    margin: auto;
  }
  #createCategoryRightArenaButton {
    flex: 0.5;
    text-align: start;
    padding-left: 52px;
  }
  #createCategoryLeftArenaButton {
    flex: 0.5;
    text-align: end;
    padding-right: 52px;
  }
  .commentActionButtonContainers {
    display: flex !important;
    justify-content: space-between !important;
  }
  .discussSubjectHeadingContainer .MuiTypography-h5 {
    width: 70%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .categoryDiscussionPageMainContainer {
    position: relative;
  }
  .discussSubjectMainContainer {
    width: 80%;
    position: relative;
    top: -40px;
  }
  .discussSubjecHeaderInfo {
    width: 20% !important;
  }
  .conclusionCommentsListContainer {
    height: 88vh;
    overflow-y: scroll;
    scrollbar-width: none;
  }
  .myCommentsPageMainContainer {
    top: -20px !important;
  }
  /* CHECK THIS */
  #headerInfoContainer .shareAndMsgContainer {
    margin: 0px 42px !important;
  }
}
@media only screen and (min-width: 1440px) {
  .categoryDiscussionPageMainContainer {
    position: relative;
  }
  .discussSubjectMainContainer {
    width: 80%;
    position: relative;
    top: -40px;
  }
  .discussSubjecHeaderInfo {
    width: 100% !important;
    justify-content: center;
  }
}

/* Tablet display */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .controlPanelMainContainer {
    border: 1.5px solid #ffffff;
    border-radius: 30px;
    color: white;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    position: fixed;
    bottom: 56px;
    right: 25%;
    left: 25%;
    width: 50%;
    z-index: 111;
  }
  .controlPanelIcon {
    font-size: 32px !important;
  }
  .categoriesListItemContainer {
    width: 80% !important;
  }
  .loginSignupOverlap {
    top: -80px !important;
    width: 60%;
  }
  .spaceBetweenLikeCount {
    margin-right: 12px !important;
  }
  .categoryListContainer {
    margin-bottom: 68px;
    top: -42px !important;
  }
  #discussSubjectKindMobile {
    display: none;
  }
  .discussSubjectMainContainer {
    width: 96% !important;
    position: relative;
    top: -6px !important;
  }
  .sendMessageMainContainer {
    top: -48px;
    width: 56% !important;
  }
  .clientsRootListContainer {
    top: -96px !important;
  }
  .categoryDiscussHeaderInfo,
  .discussSubjecHeaderInfo {
    margin-top: 0;
  }
  .discussSubjecHeaderInfo {
    width: 100% !important;
    justify-content: center;
  }
  .resetPasswordOverlap {
    top: -60px !important;
  }
  .clientsRootListItemContainer {
    width: 100% !important;
  }
  .multiSelectMainContainer {
    width: 88% !important;
    top: -78px !important;
    z-index: 9;
  }
  /* .conclusionPageTopContent {
    margin-top: -66px;
  } */
  .multiSelectSubjectContainer {
    width: 30%;
  }
  .categoryPageCardContainers {
    min-width: 300px !important;
  }
  .categoryDiscussionPageMainContainer {
    width: 88% !important;
    position: relative;
    top: -16px;
  }
  .createCategorieBottomMainContainer {
    margin-bottom: 66px;
    width: 100% !important;
  }
  #createCategoryRoundUploader {
    width: 60% !important;
    margin: auto !important;
  }
  .createCategoryFieldsContainer {
    flex: 0.44 !important;
  }
  .conclusionTitleExtra {
    font-size: 22px !important;
  }

  .conclusionPageTopContent {
    width: 90%;
  }

  /* .conclusionCommentsListContainer,
  .discussSubjectCommentsListContainer {
    max-height: 85vh !important;
    overflow-y: scroll !important;
  } */
  .discussSubjectCommentsListContainer {
    margin-bottom: 38px;
  }

  #concusionPageBottomMainContainer {
    width: 89% !important;
    position: relative;
    top: -30px !important;
    margin-bottom: 32px;
  }

  .discussSubjectCommentsListContainer:before {
    height: 75% !important;
  }

  .conclusionCommentsListContainer:before {
    content: "";
    display: inline-block;
    position: relative;
    width: 46%;
    height: 100%;
    /* z-index: 1; */
    background-image: linear-gradient(
      rgba(26, 26, 26, 0) 0%,
      rgba(26, 26, 26, 0) 0%,
      /* // These three 'smooth' out the fade. */ rgba(26, 26, 26, 0) 60%,
      rgba(26, 26, 26, 0.2) 78%,
      rgba(26, 26, 26, 0.2) 83%,
      /* // Solid to match the background. */ rgba(26, 26, 26, 1) 101%
    );
  }
  .categoryDiscussHeaderInfo {
    justify-content: space-between;
    width: 55%;
    margin: auto;
    margin-top: 6px;
  }

  #headerInfoContainer {
    justify-content: center;
  }

  .descriptionNameContainer {
    flex-direction: column !important;
    margin-top: 8px;
    align-items: center;
  }
  .editProfileEmailMargin {
    margin-right: 0;
  }
  .commentNameLargeContainer {
    padding-left: 0 !important;
  }

  .editProfileNameMargin {
    margin-left: 0;
    margin-top: 26px;
  }
  .editProfileButtonsContainer {
    justify-content: space-evenly;
  }
  .sendMessageTopContent {
    margin-top: -50px;
  }
  .commentNameLargeContainer {
    display: flex !important;
    flex-direction: column;
  }
  .myCommentsPageMainContainer {
    width: 82% !important;
    position: relative;
    top: -88px !important;
  }
  .commentNameLargeText {
    width: 100%;
  }
  .signupSocialNetworkText {
    margin-top: 6px !important;
  }
  .landingPageMainContainer {
    width: 70%;
  }
  .loginToSystemText {
    /* margin-top: 130px; */
    margin-bottom: 20px;
  }
  .commentNameLargeText .MuiTypography-root {
    text-align: center !important;
  }
  .commentNameLargeContainer .MuiAvatar-root {
    width: 130px;
    height: 130px;
  }

  .fieldWidth {
    width: 75% !important;
  }
  .loginPageEmailLink {
    padding-right: 64px !important;
  }
  .commentConclusionFlexContainer {
    flex: 0.48 1 !important;
  }
  #conclusionSwipeable,
  #conclusionPageNumberContainer {
    max-width: 350px !important;
  }
  .successPageCardContainer {
    width: 280px !important;
  }

  .containerWithRemoveIcon {
    display: flex;
  }
  #removeButtonContainer {
    right: 290px !important;
  }

  .editSubjectBottomMainContainer,
  .createCategorieBottomMainContainer {
    top: -138px !important;
  }
  .richTextConclusionContainer {
    width: 80% !important;
  }
}

/* ----------------------------------------------------Talet display over-------------------------- */
@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .categoryDiscussionPageTopContent,
  .discussSubjectPageTopContent {
    margin: auto !important;
  }
  .spaceBetweenAnimatedLike {
    margin-right: 6px !important;
  }
  .spaceBetweenLikeCount {
    margin-right: 6px !important;
  }
  .shareAndMsgContainer {
    margin: 0 !important;
  }
  .discussSubjectCommentsListContainer {
    margin-bottom: 108px;
  }
  .grecaptcha-badge {
    display: none !important;
  }
  body.trigger-position-left #enable-toolbar-trigger {
    transform: translateY(-100vh) scale(1.3) !important;
    margin-top: 16px !important;
  }
  .discussSubjectHeadingContainer .MuiTypography-h5 {
    width: 100% !important;
  }
}

.arenaPopover .arrow::after {
  border-left-color: #262833 !important;
}

#userControlPanelComponentContainer {
  width: 320px;
  padding: 24px 0;
}

#userControlPanelComponentContainer .popoverButtonContainer {
  margin: 0 20px !important;
}

.arenaPopover .popover {
  max-width: inherit;
  border-radius: 10px;
  border: 1px solid #eaeaea;
}

.marginLeft8 {
  margin-left: 8px !important;
}

.backgroundDullWhiteColor {
  background-color: #ece9f1 !important;
}

.categoryActiveDiscussionContainer {
  display: flex;
  justify-content: space-between;
}
#readMoreComponentContainer,
.activeCardAndHeading,
.categoryPageCardContainers {
  flex: 0.44;
  margin-bottom: 16px;
}
.categoryPageCardContainers {
  margin-right: 16px;
  min-width: 350px;
  margin-bottom: 36px;
}
.activeCardAndHeading {
  display: flex;
  flex-direction: column;
}
.showFullText {
  max-height: 200px;
  overflow-y: scroll;
  scrollbar-width: none;
}
.showHalfText {
  max-height: 100px;
  overflow: hidden;
}
#readMoreAndIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  text-transform: initial;
}
.headingWithImgContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
}
.conclusionPageHeaderHeading,
#conclusionPageHeaderHeading {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: flex-end;
  white-space: nowrap;
}
#concusionPageBottomMainContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: space-between;
  position: relative;
  top: -30px;
}
#commentFilterMainContainer {
  display: flex;
  align-items: flex-end;
}
#commentFilterTextContainer {
  display: flex;
  flex-direction: column;
  margin-right: 14px;
  cursor: pointer;
}
.commentConclusionFlexContainer {
  flex: 0.46;
}
#conclusionPageNumberContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 32px 0;
  align-items: center;
  max-width: 500px;
}
.conclusionCommentsListContainer::-webkit-scrollbar {
  width: 0px !important;
  background: transparent; /* Chrome/Safari/Webkit */
  scrollbar-width: none;
}
.discussSubjectCommentsListContainer::-webkit-scrollbar {
  width: 0px !important;
  background: transparent; /* Chrome/Safari/Webkit */
  scrollbar-width: none;
}
* {
  scrollbar-width: none;
}

#myCommentsPageRedHeading,
#myCommentsHeadingInfo {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.myCommentsPageMainContainer {
  width: 60%;
  position: relative;
}

.discussSubjectCommentsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.discussSubjectTopMainContainer,
.editSubjectTopMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}
.editSubjectTopMainContainer {
  z-index: 9 !important;
}
#discussSubjectUserpillContainer {
  margin-bottom: 26px;
}
.multiSelectMainContainer {
  width: 80%;
  position: relative;
  top: -72px;
  z-index: 9;
}

.wrapperClassName {
  background-color: #282c34 !important;
}
.toolbarClassName {
  background-color: #262833 !important;
  border: 1px solid #9a9a9a !important;
}
.editorClassName {
  color: #ece9f1;
  border: 1px solid #9a9a9a !important;
  background: #262833;
  padding: 14px !important;
}

.rdw-option-wrapper {
  background-color: #262833 !important;
  border-color: #282c34 !important;
}
.rdw-option-wrapper:hover {
  box-shadow: 1px 1px 0px #282c34 !important;
}
.rdw-dropdown-wrapper {
  background-color: #282c34 !important;
  border-color: #282c34 !important;
}
.rdw-dropdown-wrapper:hover {
  box-shadow: 1px 1px 0px #282c34 !important;
}
.rdw-dropdown-selectedtext {
  color: #ece9f1 !important;
}
.rdw-text-align-wrapper {
  border-right: 1px solid #ece9f1 !important ;
}
.rdw-image-wrapper {
  border-right: 1px solid #ece9f1 !important ;
}
.rdw-link-wrapper {
  border-right: 1px solid #ece9f1 !important ;
}
.rdw-inline-wrapper {
  border-right: 1px solid #ece9f1 !important ;
}
.borderRight {
  border-right: 1px solid #ece9f1 !important;
}
.uploaderMainContainer,
.roundUploaderContainer {
  height: 150px;
  width: 100%;
  border: 2px dashed #9a9a9a;
  background: #262833;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
}

.roundUploaderContainer {
  border-radius: 50%;
  height: 150px;
  width: 150px;
}

.editSubjectBottomMainContainer,
.createCategorieBottomMainContainer {
  width: 80%;
  position: relative;
  top: -60px;
}
.displayFlexEnd {
  display: flex;
  justify-content: flex-end;
}
.containerWithRemoveIcon {
  position: relative;
}
#removeButtonContainer {
  position: absolute;
  right: 450px;
  top: 8px;
}
.dropdownContainer,
.textfieldContainer {
  margin-top: 20px;
}
.editSubjectDropdownContainer,
.editSubjectTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 80px;
}
.editSubjectTextContainer {
  margin-right: 80px;
}
.editSubjectTextAndDropDownContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  align-items: flex-start;
}
.formTypeContainer {
  flex-direction: column;
}
.richTextFieldContainer,
.multilineTextFieldContainer {
  width: 80%;
  margin-top: 36px;
}
.multilineTextFieldContainer {
  width: 30%;
}
#editSubjectMultilineInput {
  margin-top: 16px;
}
.dropdownContainer .MuiFormControlLabel-root {
  color: #ffffff !important;
  display: flex;
  /* flex-direction: row-reverse; */
  margin-left: 14px !important;
  margin-right: -11px !important;
}

.dropdownContainer .MuiFormGroup-root {
  flex-direction: row !important;
  justify-content: flex-end;
}

.dropdownContainer .MuiRadio-root {
  color: #65d2fc;
}

.dropdownContainer .MuiRadio-colorSecondary.Mui-checked {
  color: #65d2fc;
}
.editSubjectButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 36px;
}
.multilineTextFieldContainer .MuiInputBase-root {
  border: 1px solid #9a9a9a !important;
}

.textfieldContainer .MuiInputBase-root {
  border: 1px solid #9a9a9a !important;
}
.DraftEditor-editorContainer {
  height: 30vh !important;
}
/*--------Allocation Page ---------------*/

#allocationPageChartMainContainer {
  display: flex;
}
#allocationPageChatDetailContainer {
  flex: 0.5;
  display: flex;
  justify-content: center;
  padding-right: 26px;
}
#allocationPageChartContainer {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  padding-left: 26px;
}
#chartDetailContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/* #allocationPageChartContainer .highcharts-text-outline{
  display: none !important;

} */

.highcharts-label > text {
  fill: #fff !important;
  font-size: 14px !important;
  background-color: #65d2fc !important;
  font-weight: normal !important;
}
/* #allocationSendButton{
  margin-top: 25px ;
} */
/*--------Allocation Page ---------------*/
#richTextHtmlField {
  background: #262833;
  color: #ffffff;
  width: 100%;
}
.changePasswordModal .MuiPaper-root {
  background: #262833;
  min-width: 500px;
  position: relative;
}

.changePasswordModal {
  z-index: 1400 !important;
}

.socialShareModal .MuiPaper-root {
  background: #262833;
  min-width: 440px;
  position: relative;
}
#changePasswordSendButtonContainer {
  display: flex;
  justify-content: center;
  margin: 26px 0px;
}
.changePasswordCloseIcon {
  position: absolute !important;
  left: 0;
}
.changePasswordInputFieldContainer .MuiInputBase-root {
  border: 1px solid #9a9a9a !important;
}

#shareModalButtonContainer {
  display: flex;
  flex-direction: row;
  padding: 0 54px;
  justify-content: space-around;
  padding-bottom: 50px;
}
.shareModalSingleButton {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialShareModal .MuiDialogTitle-root {
  padding-top: 30px !important;
  padding-bottom: 15px !important;
}

.arenaSlider .MuiSlider-rail {
  height: 14px !important;
  border-radius: 6px !important;
  background: #262833 !important;
  opacity: initial !important;
}

.arenaSlider .MuiSlider-track {
  height: 14px !important;
  border-radius: 6px !important;
  background-color: transparent !important;
}

.arenaSlider .MuiSlider-thumb {
  width: 24px;
  height: 24px;
}

.arenaSlider .MuiSlider-valueLabel {
  left: initial;
}
.allocationPageSliderContainer {
  padding: 0 26px;
  margin-bottom: 26px;
}

.arenaSlider .MuiSlider-markLabelActive {
  transform: initial !important;
  border-radius: initial !important;
}
.arenaSlider .MuiSlider-valueLabel > span {
  border-radius: 6px !important;
  transform: initial !important;
  background-color: #262833 !important;
  border: 1px solid #9a9a9a;
  width: 56px;
}

.arenaSlider .MuiSlider-valueLabel > span > span {
  transform: initial !important;
}
#createCategoriInputFieldContainer,
#createCategoriUploaderContainer {
  display: flex;
  justify-content: space-around;
}
#createCategoriUploaderContainer {
  margin-top: 26px;
}
#createCategoriButtonContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 36px;
  text-align: left;
}
.createCategoryFieldsContainer {
  flex: 0.4;
  margin-top: 16px;
}

.roundUploaderImg {
  border-radius: 50%;
}
#dialogLinks {
  display: flex;
  flex-direction: column;
}
.createCategoryFieldsContainer .MuiFormControl-root {
  border: 1px solid #9a9a9a;
}
.arenaInputField
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}
.arenaPassworField
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}
.allocationConstantField {
  width: 10%;
}

#conclusionHeadingContainer {
  display: flex;
  align-items: center;
  margin-top: 16px;
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.editSubjectBottomMainContainer .MuiFormHelperText-root {
  position: absolute !important;
  top: 60px !important;
  left: 0 !important;
}

.arenaInputField textarea.MuiInputBase-inputMultiline::-webkit-scrollbar {
  width: 0px !important;
  background: transparent;
  scrollbar-width: none;
}

#categoryPageDemoButton {
  position: absolute;
  left: 0;
  cursor: pointer;
  z-index: 1001;
}

#logoutContainer {
  position: absolute;
  right: 8px;
  color: #fff;
  cursor: pointer;
  display: none;
}

.pd-btm-0 {
  padding-bottom: 0 !important;
}

.imageUploadContainer {
  width: 50%;
}
#imageUploaderName {
  flex: 1;
}

#imageUploaderContainer {
  display: flex;
  align-items: center;
}

.allocationPageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#allocationPageBottomContainer {
  flex-direction: column;
  width: 100%;
}

.marginTop-16 {
  margin-top: 16px !important;
}

#listItemAvatar {
  cursor: pointer;
}

#backToCategoriesButton {
  position: absolute;
  top: 8px;
  right: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: rgb(101, 210, 252) !important;
}

#conclusionPageContainer {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.discussCardImgContainer img {
  object-fit: cover;
}

.commentAndDivider hr {
  background: #686868 !important;
}

.displayFlex {
  display: flex !important;
}

.justContentFlexEnd {
  justify-content: flex-end;
}

.fullWidth {
  width: 100%;
}

#imageUploaderContainer .uploaderMainContainer {
  height: 80px;
  width: 80px;
  border: 2px solid #9a9a9a;
  margin-right: auto;
}

.dropDownIcon {
  color: white !important;
  margin-right: 12px !important;
  height: 20px !important;
  width: 32px !important;
}
#allocationConclusionContainer {
  display: flex;
}
#allocationConclusionLegendContainer {
  flex: 0.5;
  display: flex;
  justify-content: center;
  padding-right: 26px;
  flex-direction: column;
}

#allocationConclusionContentContainer {
  flex: 1;
}
.allocationPageLegendContainer {
  margin-bottom: 36px;
}

.marginAuto {
  margin-left: auto;
  margin-right: auto;
}

.marginLeft12 {
  margin-left: 12px !important;
}

#unansweredAllocationLegend .allocationLegendContainer {
  margin-left: 12px;
}

#arenaModalContentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 12px;
}

#arenaModalActionContainer {
  justify-content: center;
  padding-bottom: 36px;
}

.marginBottom4 {
  margin-bottom: 4px !important;
}

.attachmentPopover .popover-inner {
  padding: 8px 0;
}

.controlPanelOption {
  padding: 6px 0;
}

#messagesContainer {
  width: 300px;
  display: flex;
  flex-direction: column;
  height: 350px;
  overflow-y: scroll;
  scrollbar-width: none;
}

#progressBarContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.constrainedStatsHeight {
  max-height: 112px;
  overflow: hidden;
}
#headerContainer {
  text-align: left;
  background-color: #2e2f3070;
  padding: 8px;
  position: fixed;
  top: 0;
  z-index: 11;
  width: 100%;
}

.logoHeader {
  width: 60px;
}

.commentNumberContainer {
  position: absolute;
  bottom: -40px;
  margin-right: 10px;
}

#commentTextContainer {
  cursor: pointer;
  width: 100%;
  text-align: right;
}

.updateModalTextField {
  display: flex;
  justify-content: center;
  margin: 12px 0;
}

.updateModalTextField .MuiInputBase-root {
  border: 1px solid #9a9a9a !important;
}

.pd-right-0 {
  padding-right: 0 !important;
}

.updateModalInput .MuiInputBase-input {
  text-align: right;
}

#updateModalActionContainer {
  justify-content: center;
  padding: 16px 34px 34px 34px !important;
}

.arenaModalActionButton {
  width: 35% !important;
  color: #65d2fc !important;
  min-height: 46px !important;
  padding: 5px 24px !important;
}

#commentListContainer {
  margin-bottom: 64px;
}

.cursorPointer {
  cursor: pointer;
}

#controlPanelIcon {
  color: #fff;
}

#topContainerContent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.positionFixed {
  position: fixed;
}

.positionSticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

#categoryDiscussionTopContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 55%;
  margin: auto;
  margin-top: 36px;
}

.positionRelative {
  position: relative;
}

#lottie {
  position: absolute;
  width: 100vw;
  height: 100vh;
}
.spaceBetweenAnimatedLike {
  margin-right: 42px;
}

.justifyContentCenter {
  justify-content: center !important;
}

.alignItemsCenter {
  align-items: center !important;
}

.discussCardShareContainer {
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: flex-end !important;
  padding: 0 56px !important;
}

.zIndex1 {
  z-index: 1;
}

.conclusionPageTopContent.positionSticky,
#discussSubjectHeadingImgContainer.positionSticky {
  position: sticky;
  top: 2vh;
  z-index: 1;
}

.discussSubjectPageTopContent {
  z-index: 1;
}

#conclusionPageContainer.insideSticky,
.discussSubjectCommentsContainer {
  align-items: flex-start;
}
.insideSticky .conclusionPageConclusionContainer.commentConclusionFlexContainer,
.discussPageReadMoreContainer.commentConclusionFlexContainer {
  position: sticky;
  top: 23vh;
  z-index: 1;
}

.insideSticky .conclusionTitleExtra,
.insideSticky .discussTitleExtra {
  position: sticky;
  top: 23vh;
  z-index: 1;
}

.insideSticky #filterContainer {
  position: sticky;
  top: calc(23vh + 42px);
  z-index: 1;
}

.insideSticky .conclusionCommentsListContainer,
.discussSubjectCommentsListContainer {
  z-index: -1;
}

#allocationPageReadMoreContainer.positionSticky {
  position: sticky;
  top: 23vh;
  z-index: 1;
}

.svgBlueColor path {
  fill: #61dafb !important;
}

.svgWhiteColor path {
  fill: #ece9f1 !important;
}

.svgRedColor path {
  fill: #fd4f42 !important;
}
#pastMessageContainer {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
  margin-bottom: 36px;
  scrollbar-width: none;
}

#messageUserAndDateText {
  display: flex;
  align-items: center;
}

#messageListItemText {
  max-height: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.svgGreenColor path {
  fill: #4bd258 !important;
}

.disabledField {
  opacity: 0.2;
}

#noMessageFoundPlaceHolder {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

::-webkit-scrollbar {
  width: 0px !important;
  background: transparent; /* Chrome/Safari/Webkit */
  scrollbar-width: none;
}

.marginNone {
  margin: 0 !important;
}

.discussSubjecHeaderInfo .shareAndMsgContainer {
  padding: 0 48px;
}
#setting:focus,
#user:focus,
#search:focus,
#alert:focus,
#message:focus {
  background: linear-gradient(#424141, transparent) !important;
}
.shareAndMsgButton:focus {
  background-color: #2e2f3070 !important;
}
#backToCategoriesButton:focus {
  background-color: rgb(101, 210, 252) !important;
}
.sendButton:focus {
  background-color: #2e2f3070 !important;
}
.commentIconButton:focus {
  background-color: #2e2f3070 !important;
}

#discussSubjectTextField:focus,
.loginInputFieldContainer .MuiInputBase-root:focus {
  border-radius: 8px;
}

.commentSubjectName {
  background-color: #1f2429 !important;
}

#heartIcon {
  font-size: 22px;
  margin: 14px 12px;
}

.actionButtonSendMessage {
  z-index: 11;
  cursor: pointer;
}

#allocationSendButton {
  margin-bottom: 32px;
}

.sendMessageMainContainer .material-icons {
  display: flex !important;
}

#sendMessageButtonContainer {
  margin-bottom: 32px;
}

.goldenColor {
  color: rgb(254, 160, 48) !important;
}

#updateModal .MuiTypography-h6 {
  font-size: 28px !important;
}

#updateModal {
  padding-top: 14px !important;
}

.marginRight30 {
  margin-right: 30px !important;
}

.multiSubjectIcon {
  color: #65d2fc;
  font-size: 72px !important;
  margin-bottom: 20px;
  width: 100% !important;
  text-align: center;
}

#conclusionSwipeable {
  max-width: 500px;
  min-height: 240px;
  max-height: 260px;
}

.underlineText {
  text-decoration: underline !important;
}

.carousel-control-next,
.carousel-control-prev {
  align-items: flex-end !important;
  bottom: 16px !important;
  /* width: 65% !important; */
}

#messageSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.arenaInputField > .MuiOutlinedInput-multiline {
  padding: 0 !important;
}

.textFieldPadding {
  padding: 14px !important;
}

.richTextFieldContainer .public-DraftStyleDefault-block {
  margin: 0 !important;
}

.sr-only {
  position: absolute !important;
  left: 0px !important;
  top: auto !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
}

.textTransformInitial {
  text-transform: initial !important;
}

.shareAndMsgContainer .MuiButton-label {
  display: flex;
  flex-direction: column;
  text-transform: initial;
}

.shareModalSingleButton .MuiButton-label,
.multiSelectSub .MuiButton-label,
#addChoiceOption .MuiButton-label {
  display: flex;
  flex-direction: column;
  text-transform: initial;
}

.noPadding {
  padding: 0 !important;
}

.arena-dropdown__option--is-focused {
  border: 1px solid #e0e0e0 !important;
}

.uploaderButton {
  padding: 0;
  background: transparent;
  border: 0;
  display: inline-block;
  width: 100%;
  pointer-events: all;
}

.dropDownIconsContainer {
  padding: 16px 8px !important;
  background: transparent;
  display: flex;
}

.border {
  border: 1px solid #e0e0e0 !important;
}

.likeFilledButton {
  width: 60px;
  height: 60px;
  padding: 0 !important;
  margin: 0 !important;
  margin-top: 1px !important;
}
.likeFilledButton .commentIconButton {
  padding: 0 !important;
}

.animationHeart {
  margin-bottom: 1px;
  margin-left: 2px;
}
.pointerEventsNone {
  pointer-events: none !important;
}

.MuiPickersModal-dialogRoot .MuiDialogActions-root.MuiDialogActions-spacing {
  background: #262833 !important;
}

.marginBottom12 {
  margin-bottom: 12px !important;
}

tspan.highcharts-text-outline {
  stroke-width: 1px !important;
}

.normalCardActionButtons {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: flex-end;
  margin-top: 10px;
}

.datePickerDialog .MuiPickersClockNumber-clockNumber {
  color: #fff !important;
}
.datePickerDialog .MuiPickersClockPointer-pointer,
.datePickerDialog .MuiPickersClock-pin {
  background-color: #4bd258 !important;
}
.datePickerDialog .MuiPickersClockPointer-thumb {
  border-color: #4bd258 !important;
}

/* .selectedOption .MuiButton-root:hover {
  width: 100% !important;
} */

.flexDirectionRowReverse {
  flex-direction: row-reverse !important;
}

#deleteButtonContainer {
  margin-right: 50px !important;
}

.radioButtonContainer .MuiFormGroup-root {
  flex-direction: row-reverse !important;
}

.multiSelectSubjectContainer .MuiButtonBase-root {
  width: 100%;
  border-radius: 15px;
  padding: 12px 0;
}

#backToCategoriesButton .MuiButton-endIcon {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.actionButtonSpacing {
  margin-right: 8px !important;
}

.datePickerDialog .MuiPickersDay-dayDisabled .MuiIconButton-label,
.datePickerDialog
  .MuiPickersCalendarHeader-iconButton.Mui-disabled
  .MuiIconButton-label {
  color: gray !important;
}

.flexDirectionRow .css-yk16xz-control {
  flex-direction: row !important;
}

.fieldWidth {
  width: 70%;
}

.loginPageEmailLink {
  padding-right: 90px;
}
.marginBottom24 {
  margin-bottom: 24px !important;
}
#commonStructureTopContent:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 110%;
  background-image: linear-gradient(
    rgba(26, 26, 26, 0) 0%,
    rgba(26, 26, 26, 0) 0%,
    /* // These three 'smooth' out the fade. */ rgba(26, 26, 26, 0) 0%,
    rgba(26, 26, 26, 0.6) 73%,
    rgba(26, 26, 26, 0.8) 85%,
    /* // Solid to match the background. */ rgba(26, 26, 26, 1) 90%
  ) !important;
  background-color: rgba(0, 0, 0, 0.3);
}

.svgGoldenColor path {
  fill: rgb(254, 160, 48) !important;
}

#readMoreAndIcon .MuiButton-label {
  flex-direction: row-reverse !important;
  align-items: baseline !important;
}

.conclusionAttachmentPopover .popover.show.bs-popover-auto {
  max-width: 300px !important;
  justify-content: flex-end !important;
}
.fade {
  transition: none !important;
}
.carousel-control-prev:focus,
.carousel-control-next:focus {
  opacity: 0.5 !important;
}
.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 0.9 !important;
}

.marginLeft4 {
  margin-left: 4px !important;
}

#counterContainer .MuiTypography-h4 {
  font-size: 28px !important;
}

.counterConstrainedHeight {
  height: 0 !important;
  overflow: hidden;
}

#searchComponentSendButton {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.searchCardTitle {
  min-height: 60px;
}

.successPageCardContainer {
  margin-right: 16px;
  width: 350px;
  margin-bottom: 36px;
}
.searchPageMainContainer {
  width: 80%;
}

.searchPageCardContainer {
  justify-content: start !important;
}
.searchStringContainer {
  margin-bottom: 24px !important;
}

#searchComponentContainer {
  min-width: 350px;
  padding: 24px 12px;
}

.search .popover-inner {
  background: black !important;
}

.marginRight4 {
  margin-right: 4px !important;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

.downloadContainer {
  justify-content: flex-start !important;
}

#userControlPanelComponentContainer .downloadContainer .popoverButtonContainer {
  margin: 0 !important;
}

.commentLogo {
  border-radius: 50% !important;
  width: 150px !important;
  height: 150px !important;
}

#progressValue {
  position: absolute;
  height: 94px;
  top: 14px;
}

#progressBar {
  position: relative;
  max-width: 80px;
}

#accountEmptyContainer {
  align-items: center !important;
  width: 100%;
}

.grecaptcha-badge {
  z-index: 1600;
}

.sendButton {
  width: 104px !important;
}
.zIndex0 {
  z-index: 0 !important;
}

.commentMainButton {
  min-width: 140px !important;
}

#progressBar .CircularProgressbar-trail {
  stroke: #3c3e4e !important;
}

#sendMessageContainerTopContent {
  width: 55% !important;
}

#messageListContainer {
  max-height: 325px;
  overflow: hidden;
}
#messageListContainerInitial {
  max-height: initial;
}
.MuiTypography-body1 {
  font-size: 16px !important;
}
.fontSize18 {
  font-size: 18px !important;
}

#commentFilterTextContainer .MuiButton-label {
  font-size: 16px !important;
}

#resetPasswordButton {
  min-width: 150px !important;
}

.shareIconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MuiTooltip-tooltipPlacementLeft {
  font-size: 16px !important;
}
#termsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: right;
  max-width: 840px;
  margin: auto;
  padding: 48px 24px;
}
.marginBottom48 {
  margin-bottom: 36px !important;
}
.footerLink {
  cursor: pointer;
}
.textAlignStart {
  text-align: start !important;
}
.arenaChipInput .MuiOutlinedInput-adornedStart {
  padding: 8px 6px !important;
}
.arenaChipInput.arenaInputField .MuiInputBase-input {
  padding-top: 4px !important;
  padding-bottom: 10px !important;
  padding-right: 4px !important;
}

#userGroupsDropDown {
  min-height: 54px !important;
}

.arenaChipInput .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
@media (min-width: 320px) and (max-width: 340px) {
  .categoryPageCardContainers {
    min-width: auto !important;
    margin-right: 0px !important;
  }
}
.notFoundImage {
  margin: 32px 0;
}
.notFoundMainContainer {
  max-width: 600px;
  text-align: center;
}
.socialShareModal {
  z-index: 1300 !important;
}
.arenaPopover .popover {
  z-index: 1300 !important;
}

.marginText {
  margin: 0 8px !important;
}
.clientsRootListContainer .infinite-scroll-component__outerdiv,
.categoryListContainer .infinite-scroll-component__outerdiv {
  width: 100% !important;
}
#subjectPagePagination {
  display: flex;
  justify-content: center;
  margin: 64px 0;
  align-items: center;
}
